import React from "react"
import { chakra, Box, Flex } from "@chakra-ui/react"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  Hero,
  Overlay,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import { Container } from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

const HeroImage = chakra(GatsbyImage)

function GenericPagesHero({ page, heroimg, ...props }) {
  return (
    <Hero overflow="hidden" width="full" height="full" position="relative">
      <Box
        sx={{
          maxHeight: "520px",
          objectPosition: `center center`,
          position: "relative",
          overflow: "hidden",
          width: "full",
        }}
      >
        {page.heroImage ? (
          <HeroImage
            image={page.heroImage.gatsbyImageData}
            display="block"
            minHeight="400px"
            maxHeight="520px"
            layout="fullWidth"
            alt={`${page.title} - WVU Cancer Institute`}
          />
        ) : (
          <HeroImage
            image={heroimg}
            display="block"
            minHeight="400px"
            maxHeight="520px"
            layout="fullWidth"
            alt={`${page.title} - WVU Cancer Institute`}
          />
        )}
        <Overlay opacity="0.85" bg="accent.3" />
        <Box
          position="absolute"
          overflow="hidden"
          width="full"
          height="full"
          zIndex={5}
          top={0}
          left={0}
          right={0}
          bottom={0}
        >
          <Container height="full">
            <Flex
              alignItems="center"
              width={["full", "full", "75%", "60%"]}
              height="full"
              sx={{
                a: {
                  color: "white",
                  textDecoration: "none",
                },
              }}
            >
              <Box as="h1" fontWeight="normal" color="white">
                {props.eyebrow} <br />
                <Box as="span" fontWeight="bold">
                  {props.title || page.title}
                </Box>
              </Box>
            </Flex>
            <Box width="full">{props.children}</Box>
          </Container>
        </Box>
      </Box>
    </Hero>
  )
}

export default GenericPagesHero
